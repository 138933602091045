import logo from "./logo.svg";
import "./App.css";
import DownloadId from "./DownloadId.tsx";
import DownloadUser from "./DownloadUser.tsx";
import React from "react";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <img src={logo} className="App-logo" alt="logo" />
          <br />
          <br />
          Splits.io has shut down permanently for financial reasons.
        </p>
        <p>
          For more info, see my{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twos.dev/splitsio.html"
          >
            blog post
          </a>
          .<br />
          For an alternative to Splits.io, see{" "}
          <a target="_blank" rel="noreferrer" href="https://therun.gg">
            The Run
          </a>
          .
        </p>
        <p style={{ marginTop: "4rem" }}>You can still retrieve your data.</p>
        <DownloadId />
        <DownloadUser />
        <br />
        <br />
        <p>
          If you have more complex archival needs than this,
          <br />
          <a href="mailto:ben@twos.dev">email me</a> and I'll see what I can do.
          <br />
          All data is backed up.
          <br />
        </p>
        <br />
        <p>
          Splits.io remains{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/glacials/splits-io"
          >
            open source
          </a>
          .<br />
          Anyone who wants to spin up a copy has my blessing.
        </p>
        <br />
        <br />
        <p>
          Thank you for 10 years of going fast.
          <div style={{ textAlign: "left" }}>
            <br />
            —Ben "Glacials" Carlsson (<a href="https://twos.dev">twos.dev</a>)
            <br />
            —BatedUrGonnaDie (
            <a href="https://leagueofnewbs.com/">leagueofnewbs.com</a>)
            <br />
            —Franklyn "Tuna" Rodriguez
          </div>
        </p>
        <p>
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
            href="https://www.reddit.com/r/speedrun/comments/1r3buu/i_made_a_pastebinlike_site_for_splits_any_use/"
          >
            ❤️
          </a>
        </p>
        <br />
        <br />
        <pre>
          Final stats:
          <br />
          485,249 runs
          <br />
          9,273,126 splits
          <br />
          103,575 users
          <br />
          52,482 games
        </pre>
      </header>
    </div>
  );
}

export default App;
